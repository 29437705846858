import gql from 'graphql-tag';

export const GET_TOTAL_BUS_LOAD = gql`
    query getNumberOfPassenger($driver_bus_route_id: Int!, $company_id: Int!) {
        getNumberOfPassenger(driver_bus_route_id: $driver_bus_route_id, company_id: $company_id) 
    }
`;




