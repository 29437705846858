<template>
    <ion-page>
        <div class="header-container">
            <ion-label class="modal-title">{{ operatorName }}</ion-label>
        </div>
        <ion-content>
            <div class="container">
                <ion-text class="sub-text sub-top"><span><b>Route:</b> {{ routeName }}</span></ion-text>
                <ion-text class="sub-text sub-top"><span><b>Bus number: </b> {{ busNumber }}</span></ion-text>
                <ion-text class="sub-text sub-top" v-if="totalBusLoad && user.is_premium && showPaxCount"
                    ><b>Bus load:</b> {{ totalBusLoad }} pax</ion-text
                >
                <div class="toggle-container" v-if="user.is_premium">
                    <ion-text class="sub-text">Route Map Overlay</ion-text>
                    <ion-toggle
                        mode="md"
                        @ionChange="toggleChange($event)"
                        slot="end"
                        name="cherry"
                        :checked="computedShowRouteLine"
                    ></ion-toggle>
                </div>
            </div>
            <ion-button @click="dismissModal()" class="close-btn" :disabled="loading" fill="clear" size="small">Close</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'
import routeApi from '../../util/apis/route'
import busApi from '../../util/apis/bus'
import appService from '../../util/services/appService'

export default {
    name: 'BusInforModal',
    props: {
        busInfo: Object,
        showRouteLines: Object,
    },
    data: function() {
        return {
            routeLines: this.busInfo.routeViewModel.model.routeLines || [],
            loading: false,
            totalBusLoad: 0,
            busModalInfor: null,
        }
    },
    created() {
        this.showRouteLineSettings = this.showRouteLines
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        operatorName() {
            return this.busInfo.routeViewModel.operatorName
        },
        routeName() {
            return this.busInfo.routeViewModel.routeName
        },
        showPaxCount() {
            return this.busInfo.paxAppShowPaxCount
        },
        busNumber() {
            return this.busInfo.busNumber
        },
        computedShowRouteLine() {
            return this.showRouteLineSettings[this.busInfo.routeId]
        },
    },
    async mounted() {
        if (this.user.is_premium && this.showPaxCount) {
            await this.handleGetBusLoad(this.busInfo)
        }
    },
    methods: {
        async handleGetBusLoad(busInfo) {
            const resBusLoad = await busApi.getTotalBusLoad({
                driver_bus_route_id: busInfo.dbrId,
                company_id: busInfo.operatorId,
            })
            this.totalBusLoad = resBusLoad.data.getNumberOfPassenger
        },
        async toggleChange(event) {
            try {
                this.showRouteLineSettings[this.busInfo.routeId] = event.target.checked
                if (event.target.checked && this.routeLines.length == 0) {
                    this.loading = true

                    const resRouteLines = await routeApi.getRouteLine({
                        route_id: this.busInfo.routeId,
                        company_id: this.busInfo.operatorId,
                    })
                    if (resRouteLines.data.getRouteLines.length > 0) {
                        this.routeLines = resRouteLines.data.getRouteLines
                    } else {
                        await appService.presentAlert({
                            header: 'Warning',
                            message: `${this.busInfo.routeViewModel.routeName} has no route lines`,
                        })
                    }
                }
            } finally {
                this.loading = false
            }
        },
        dismissModal() {
            if (this.loading) return
            modalController.dismiss({
                routeLines: this.showRouteLineSettings[this.busInfo.routeId] ? this.routeLines : [],
                bus: {
                    route_id: this.busInfo.routeId,
                    company_id: this.busInfo.operatorId,
                },
                busColor: this.busInfo.routeViewModel.markerDefinition.color,
                routeModel: this.busInfo.routeViewModel.model,
            })
        },
    },
}
</script>
<style scoped>
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}
.sub-text.sub-top {
    margin-top: 12px;
}
.sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
}
.text-over {
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;

    overflow: hidden;
}
.toggle-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;
}
.close-btn {
    position: absolute;
    font-size: 15px;
    right: 8px;
    bottom: 5px;
}
.bus-infor-modal .modal-wrapper {
    max-width: 400px;
    width: 90%;
    height: 200px;
    border-radius: 6px;
}
.no-scroll {
    --overflow: hidden;
}
.modal-title {
    text-align: left;
    font-weight: bold;
    color: white;
}
</style>
