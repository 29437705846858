<template>
    <google-map-js
        v-if="platform == 'web'"
        :stop-markers="computedStopMarkers"
        :bus-markers="computedBusMarkers"
        :route-paths="routePaths"
        :map-zoom="mapZoom"
        :onClickStopMarker="onClickStopMarker"
        :onClickBusMarker="onClickBusMarker"
        :my-marker-options="myMarkerOptions"
        :on-map-ready="onMapReady"
        ref="googleMapJs"
    ></google-map-js>
    <google-map-native
        :stop-markers="computedStopMarkers"
        :bus-markers="computedBusMarkers"
        :route-paths="routePaths"
        :map-zoom="mapZoom"
        :on-click-stop-marker="onClickStopMarker"
        :onClickBusMarker="onClickBusMarker"
        :get-bottom-sheet-height="getBottomSheetHeight"
        :show-btn-menu-bottom="computedShowBtnMenuBottom"
        :show-stop-times="computedShowStopTimes"
        :on-map-ready="onMapReady"
        ref="googleMapNative"
        v-else
    ></google-map-native>
</template>
<script>
import { Capacitor } from '@capacitor/core'
import GoogleMapJs from './GoogleMapJS.vue'
import GoogleMapNative from './GoogleMapNative.vue'
export default {
    name: 'GoogleMapControl',
    components: {
        GoogleMapJs,
        GoogleMapNative,
    },
    props: {
        stopMarkers: Array,
        busMarkers: Array,
        routePaths: Array,
        onClickStopMarker: Function,
        onClickBusMarker: Function,
        myMarkerOptions: Object,
        mapZoom: Number,
        getBottomSheetHeight: Function,
        onMapReady: Function,
        showBtnMenuBottom: Boolean,
        showStopTimes: Boolean,
    },
    data: function() {
        return {
            platform: Capacitor.getPlatform(),
        }
    },
    computed: {
        isWebPlatform() {
            return this.platform == 'web'
        },
        computedMyCoordinates() {
            return this.myCoordinates
        },
        computedStopMarkers() {
            return this.stopMarkers
        },
        computedBusMarkers() {
            return this.busMarkers
        },
        computedRouteLines() {
            return this.routePaths
        },
        computedShowBtnMenuBottom() {
            return this.showBtnMenuBottom
        },
        computedShowStopTimes() {
            return this.showStopTimes || false
        },
    },
    methods: {
        async updateMapViewWhenBoundingRectUpdated() {
            if (this.$refs && this.$refs.googleMapNative) {
                await this.$refs.googleMapNative.updateMapViewWhenBoundingRectUpdated()
            }
        },
        async resizeMapViewToFullScreen(options) {
            if (this.$refs && this.$refs.googleMapNative) {
                await this.$refs.googleMapNative.resizeMapViewToFullScreen(options)
            }
        },
        async closeMap() {
            if (!this.isWebPlatform && this.$refs.googleMapNative) {
                await this.$refs.googleMapNative.closeMap()
            }
        },
        async hideMapView() {
            if (!this.isWebPlatform) {
                await this.$refs.googleMapNative.hideMapView()
            }
        },
        async moveToLocation(location) {
            if (!this.isWebPlatform) {
                await this.$refs.googleMapNative.setMapCamera(location)
            } else {
                console.log(this.$refs)
                this.$refs.googleMapJs.panTo(location)
            }
        },
        async showMapView() {
            if (!this.isWebPlatform) {
                await this.$refs.googleMapNative.showMapView()
            }
        },
        async renderRouteLines(routePaths) {
            if (!this.isWebPlatform) {
                await this.$refs.googleMapNative.renderRouteLines(routePaths)
            }
        },
        hideBackgroundImg() {
            if (!this.isWebPlatform) {
                this.$refs.googleMapNative.unSetMapImg()
            }
        },
    },
}
</script>
<style scoped>
</style>
