<template>
    <div class="google-map">
        <GoogleMap
            ref="mapRef"
            :center="myCoordinates"
            :zoom="mapZoom"
            :api-key="API_KEYS"
            style="width: 100%; height: 100%"
        >
            <Marker
                :key="index"
                v-for="(m, index) in stopMarkers"
                :options="m"
                :clickable="true"
                @click="onClickStopMarker(m)"
            />
            <Marker :key="`bus${index}`" v-for="(m, index) in busMarkers" :options="m" @click="onClickBusMarker(m)" />
            <Marker v-if="myMarkerOptions" :options="myMarkerOptions" />
            <Polyline v-for="(path, index) in routePaths" :key="index" :options="path" />
        </GoogleMap>
    </div>
</template>
<script>
import { GoogleMap, Marker, Polyline } from 'vue3-google-map'
export default {
    name: 'GoogleMapJs',
    components: {
        GoogleMap,
        Marker,
        Polyline,
    },
    props: {
        stopMarkers: Array,
        busMarkers: Array,
        routePaths: Array,
        onMapReady: Function,
        onClickStopMarker: Function,
        onClickBusMarker: Function,
        myMarkerOptions: Object,
        mapZoom: Number,
    },
    data: function() {
        return {
            API_KEYS: process.env.VUE_APP_GOOGLE_API_KEY_JS,
            myCoordinates: this.$store.getters.currentLocation,
        }
    },
    mounted: function() {
        this.onMapReady()
    },
    methods: {
        panTo(location) {
            this.$refs.mapRef.map.panTo(location)
        },
    },
}
</script>
<style scoped>
.google-map {
    width: 100%;
    height: 100%;
}
</style>
