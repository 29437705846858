import apolloClient from "../../apolloClient";
import { GET_TOTAL_BUS_LOAD } from "../../graphql/queries/bus"

// import store from "../../store";

class BusApi {
    constructor() { }
    // queries 

    getTotalBusLoad(params) {
        return apolloClient.query({
            query: GET_TOTAL_BUS_LOAD,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }
}

const srv = new BusApi();
export default srv;
