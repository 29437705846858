<template>
    <ion-page>
        <ion-content class="no-scroll">
            <div class="container">
                <ion-text class="title-txt">Live Tracking Settings</ion-text>
                <div class="toggle-container">
                    <ion-text class="sub-text">Show saved stops only</ion-text>
                    <ion-toggle mode="md" :checked="showOnlyTrackingStops" @ionChange="toggleChange($event)" slot="end" name="cherry"></ion-toggle>
                </div>
                <div class="toggle-container">
                    <ion-text class="sub-text">Show stop times</ion-text>
                    <ion-toggle mode="md" :checked="showStopTimes" @ionChange="toggleStopTimeChange($event)" slot="end" name="cherry"></ion-toggle>
                </div>
            </div>
            <ion-button @click="dismissModal()" class="close-btn" fill="clear" size="small">Close</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'

export default {
    name: 'SettingBottomSheetModal',
    props: {
        paramShowOnlyTrackingStops: Boolean,
        paramShowStopTimes: Boolean,
    },
    data: function() {
        return {
            showOnlyTrackingStops: false,
            showStopTimes: false,
        }
    },
    created() {
        this.showOnlyTrackingStops = this.paramShowOnlyTrackingStops
        this.showStopTimes = this.paramShowStopTimes
    },
    methods: {
        dismissModal() {
            modalController.dismiss({
                showOnlyTrackingStops: this.showOnlyTrackingStops,
                showStopTimes: this.showStopTimes,
            })
        },
        async toggleChange(event) {
            this.showOnlyTrackingStops = event.target.checked
        },
        async toggleStopTimeChange(event) {
            this.showStopTimes = event.target.checked
        },
    },
}
</script>
<style scoped>
.no-scroll {
    --overflow: hidden;
}
.toggle-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;
}
.close-btn {
    position: absolute;
    font-size: 15px;
    right: 8px;
    bottom: 5px;
}
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}
.title-txt {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}
.sub-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--ion-color-grey);
}
</style>
