<template>
    <div id="google-map-native" class="google-map-native">
        <img :src="imageBase64" alt="" aria-hidden="true"/>
    </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, ref, watchEffect } from 'vue'
import GoogleMapPlugins from '../../util/services/googleMapPluginService'
import { useStore } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { LiveTrackingBottomSheetInitHeight } from '../../util/constant'


export default defineComponent({
    name: 'GoogleMapNative',
    props: {
        stopMarkers: {
            type: Array,
            required: true,
            default: () => [],
        },
        busMarkers: {
            type: Array,
            required: true,
            default: () => [],
        },
        routePaths: {
            type: Array,
            required: true,
            default: () => [],
        },
        onClickStopMarker: {
            type: Function,
            required: true,
            default: () => null,
        },
        onClickBusMarker: {
            type: Function,
            required: true,
            default: () => null,
        },
        mapZoom: {
            type: Number,
            required: true,
            default: () => 0,
        },
        getBottomSheetHeight: {
            type: Function,
            required: true,
            default: () => null,
        },
        onMapReady: {
            type: Function,
            required: false,
            default: () => null,
        },
        showBtnMenuBottom: {
            type: Boolean,
            required: true,
            default: () => true,
        },
        showStopTimes: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    setup(props) {
        const imageBase64 = ref('')
        const store = useStore()
        let stopMapMarkers = []
        let busMapMarkers = []
        let mapRouteLines = []
        let isMapReady = false
        let showStopTimes = false
        let googleMapNativeFullHeight = null
        const currentLocation = store.getters.currentLocation

        const init = async (boundingRect, zoom) => {
            await GoogleMapPlugins.initialize({
                key: process.env.VUE_APP_GOOGLE_API_KEY,
            })
            const initMapPayload = {
                width: Math.round(boundingRect.width),
                height: Math.round(boundingRect.height),
                x: Math.round(boundingRect.x),
                y: Math.round(boundingRect.y),
                latitude: currentLocation.lat,
                longitude: currentLocation.lng,
                zoom,
            }
            console.log(initMapPayload)

            await GoogleMapPlugins.create(initMapPayload)

            await GoogleMapPlugins.accessibilityElementsHidden({hidden: false})
        }

        const getBoundingRect = () => {
            const mapEl = document.getElementById('google-map-native')
            return mapEl.getBoundingClientRect()
        }

        const addNativeMarkers = async (markers, type) => {
            if (!isMapReady || !markers || !markers.length) return
            const add = () => {
                return Promise.all(
                    markers.map(async (marker) => {
                        console.log('addMarker', marker.routeViewModel.id)
                        const imgUrl = marker.icon.url
                        const markerData = {
                            type: type,
                            stopId: marker.stopModel ? marker.stopModel.id : null,
                            busId: marker.busId || null,
                            routeId: marker.routeId,
                            routeUID: marker.routeViewModel.id,
                            lat: marker.position.lat,
                            lng: marker.position.lng,
                        }

                        // this option will create native marker
                        const markerOptions = {
                            latitude: marker.position.lat,
                            longitude: marker.position.lng,
                            iconUrl: 'public' + imgUrl.substring(0, imgUrl.length - 4),
                            zIndex: type == 'stop' ? 1 : 2,
                            metadata: {
                                markerData,
                            },
                        }

                        if (type == 'stop') {
                            markerOptions.title = marker.stopModel.name
                            if (showStopTimes) {
                                console.log('markerTitle', showStopTimes)
                                var departureTime = marker.stopModel.departure_time ? moment(marker.stopModel.departure_time, 'HH:mm:ss').format('hh:mm'): null
                                markerOptions.markerTitle = marker.stopModel && departureTime
                                    ? `${marker.stopModel.position}-${departureTime}`
                                    : `${marker.stopModel.position}`
                            }
                            markerOptions.scale = 3
                        } else {
                            markerOptions.title = marker.routeViewModel.routeName
                            markerOptions.scale = 3.5
                        }

                        const addedMarker = await GoogleMapPlugins.addMarker(markerOptions)

                        const mapMarker = {
                            id: addedMarker.marker.id,
                            metadata: { markerData },
                        }
                        if (type == 'stop') {
                            stopMapMarkers.push(mapMarker)
                        } else if (type == 'bus') {
                            busMapMarkers.push(mapMarker)
                        }
                    })
                )
            }

            return add()
        }

        const moveNativeMarkers = async (markers) => {
            if (!isMapReady || !markers || !markers.length) return

            await Promise.all(
                markers.map((marker) => {
                    return GoogleMapPlugins.moveMarker({
                        id: marker.id,
                        latitude: marker.metadata.markerData.latitude,
                        longitude: marker.metadata.markerData.longitude,
                    })
                })
            )
        }

        const onMapReady = () => {
            GoogleMapPlugins.addListener('onMapReady', () => {
                GoogleMapPlugins.enableCurrentLocation({ enabled: true })
                GoogleMapPlugins.settings({ myLocationButton: true })
                GoogleMapPlugins.setMapType({
                    type: 'normal',
                })
                GoogleMapPlugins.setMapStyle({
                    jsonString: JSON.stringify([
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }],
                        },
                    ]),
                })

                GoogleMapPlugins.addToggleBtnBottomSheet()
                isMapReady = true
                props.onMapReady && props.onMapReady()
            })
        }

        const removeNativeMarkers = (markerIds) => {
            if (!isMapReady) {
                return Promise.resolve()
            }

            return Promise.all(
                markerIds.map((id) => {
                    return GoogleMapPlugins.removeMarker({ id })
                })
            )
        }

        const removeNativeRouteLines = (polylineIds) => {
            if (!isMapReady) {
                return Promise.resolve()
            }

            return Promise.all(
                polylineIds.map((id) => {
                    return GoogleMapPlugins.removePolylines({ id })
                })
            )
        }

        const resizeMapViewAccordingToBottomSheetHeight = (bottomSheetHeight) => {
            const bottomSheetY = bottomSheetHeight
            const boundingRect = getBoundingRect()
            googleMapNativeFullHeight = Math.round(boundingRect.height)
            console.log('didToggleBtnBottomSheetClick', Math.round(boundingRect.height - bottomSheetY + LiveTrackingBottomSheetInitHeight))
            resizeMapView(Math.round(boundingRect.height - bottomSheetY + LiveTrackingBottomSheetInitHeight))
        }

        const closeMap = async () => {
            await GoogleMapPlugins.clear()
            await GoogleMapPlugins.close()
        }
        const hideMapView = () => {
            return GoogleMapPlugins.hide()
        }
        const showMapView = () => {
            return GoogleMapPlugins.show()
        }
        const resizeMapView = async (height) => {
            const boundingRect = getBoundingRect()
            return GoogleMapPlugins.resizeMapView({
                top: boundingRect.y,
                height,
            })
        }
        const hideBottomSheetBtn = () => {
            //isMapReady && GoogleMapPlugins.hideToggleBtnBottomSheet()
        }
        const showBottomSheetBtn = () => {
            //isMapReady && GoogleMapPlugins.showToggleBtnBottomSheet()
        }
        const addListenerOnTapMarker = (onTapMarker) => {
            GoogleMapPlugins.addListener('didTap', async function (markerData) {
                onTapMarker(markerData)
                hideMapView()
            })
        }
        const resizeMapViewToFullScreen = (options) => {
            resizeMapView(googleMapNativeFullHeight)
            if (options.hideToggle) {
                hideBottomSheetBtn()
            } else {
                showBottomSheetBtn()
            }
        }

        const onTapMarker = async (data) => {
            if (data && data.result && data.result.metadata) {
                const markerData = data.result.metadata.markerData
                if (markerData.type == 'stop') {
                    const matchMarkerInfo = props.stopMarkers.find((markerInfo) =>
                        isMapMarkerMatchingMarkerInfo(data.result, markerInfo)
                    )
                    props.onClickStopMarker(matchMarkerInfo)
                } else {
                    const matchMarkerInfo = props.busMarkers.find((markerInfo) =>
                        isMapMarkerMatchingMarkerInfo(data.result, markerInfo)
                    )
                    props.onClickBusMarker(matchMarkerInfo)
                }
            }
        }

        const unSetMapImg = () => {
            imageBase64.value = ''
        }

        const setImageBase64 = (data) => {
            imageBase64.value = 'data:image/png;base64, ' + data
        }

        function isMapLineMatchingPath(mapLine, path) {
            const { busUID } = mapLine
            const { company_id, route_id } = path.bus
            return busUID == `${company_id}_${route_id}`
        }

        async function addNativeRouteLines(paths) {
            return Promise.all(
                paths.map(async (line) => {
                    const points = line.path.map((p) => ({ latitude: p.lat, longitude: p.lng }))
                    const polyline = await GoogleMapPlugins.addPolyline({
                        points,
                        strokeColor: line.strokeColor,
                        strokeWidth: 4,
                    })
                    mapRouteLines.push({
                        id: polyline.polyline.id,
                        busUID: `${line.bus.company_id}_${line.bus.route_id}`,
                    })
                })
            )
        }

        const renderRouteLines = async (paths) => {
            const mapLinesToDelete = mapRouteLines.filter((mapLine) => {
                return !paths.find((path) => {
                    return isMapLineMatchingPath(mapLine, path)
                })
            })

            const pathsToAdd = paths.filter((path) => {
                return !mapRouteLines.find((mapLine) => {
                    return isMapLineMatchingPath(mapLine, path)
                })
            })

            removeNativeRouteLines(mapLinesToDelete.map((line) => line.id))
            _.pullAll(mapRouteLines, mapLinesToDelete)
            addNativeRouteLines(pathsToAdd)
        }

        const addListenerOnHideMapView = () => {
            GoogleMapPlugins.addListener('didHideMapView', function (data) {
                const imageBase64 = data.result.imageBase64
                setImageBase64(imageBase64)
            })
        }

        const updateMapViewWhenBoundingRectUpdated = () => {
            console.log('updateMapViewWhenBoundingRectUpdated')
            const bottomSheetHeight = props.getBottomSheetHeight()
            resizeMapViewAccordingToBottomSheetHeight(bottomSheetHeight)
        }

        const setMapCamera = (location) => {
            if (location) {
                GoogleMapPlugins.setCamera({
                    latitude: location.lat,
                    longitude: location.lng,
                    zoom: props.mapZoom,
                })
            }
        }

        onMounted(async () => {
            const boundingRect = getBoundingRect()
            onMapReady()
            addListenerOnTapMarker(onTapMarker)
            addListenerOnHideMapView()
            await init(boundingRect, props.mapZoom)
        })

        onBeforeUnmount(() => {
            closeMap()
            GoogleMapPlugins.removeAllListeners()
        })

        function isMapMarkerMatchingMarkerInfo(mapMarker, markerInfo) {
            const { type, stopId, routeUID, busId } = mapMarker.metadata.markerData
            if (type == 'stop') {
                return markerInfo.stopModel.id == stopId && markerInfo.routeViewModel.id == routeUID
            } else if (type == 'bus') {
                return markerInfo.routeViewModel.id == routeUID && markerInfo.busId == busId
            }
            return false
        }

        function syncMarkers(currentMapMarkers, updatedMarkers, type, updatedShowStopTimes) {
            console.log('syncMarkers', showStopTimes)
            if (showStopTimes != updatedShowStopTimes) {
                showStopTimes = updatedShowStopTimes
                removeNativeMarkers(currentMapMarkers.map((marker) => marker.id))
                _.pullAll(currentMapMarkers, currentMapMarkers)
                addNativeMarkers(updatedMarkers, type)
            } else {
                const mapMarkersToDelete = currentMapMarkers.filter((mapMarker) => {
                    return !updatedMarkers.find((markerInfo) => {
                        return isMapMarkerMatchingMarkerInfo(mapMarker, markerInfo)
                    })
                })

                const markerInfosToAdd = updatedMarkers.filter((markerInfo) => {
                    return !currentMapMarkers.find((mapMarker) => {
                        return isMapMarkerMatchingMarkerInfo(mapMarker, markerInfo)
                    })
                })

                const mapMarkersToUpdateLocation = currentMapMarkers.filter((mapMarker) => {
                    return updatedMarkers.find((markerInfo) => {
                        const shouldUpdateLocation =
                            isMapMarkerMatchingMarkerInfo(mapMarker, markerInfo) &&
                            mapMarker.metadata.markerData.latitude != markerInfo.position.lat &&
                            mapMarker.metadata.markerData.longitude != markerInfo.position.lng

                        if (shouldUpdateLocation) {
                            mapMarker.metadata.markerData.latitude = markerInfo.position.lat
                            mapMarker.metadata.markerData.longitude = markerInfo.position.lng
                            return true
                        }

                        return false
                    })
                })
                removeNativeMarkers(mapMarkersToDelete.map((marker) => marker.id))
                _.pullAll(currentMapMarkers, mapMarkersToDelete)
                addNativeMarkers(markerInfosToAdd, type)
                moveNativeMarkers(mapMarkersToUpdateLocation)
            }
        }

        watchEffect(() => {
            console.log('====STOPS===')
            syncMarkers(stopMapMarkers, props.stopMarkers, 'stop', props.showStopTimes)
        })

        watchEffect(() => {
            console.log('====BUSES===')
            syncMarkers(busMapMarkers, props.busMarkers, 'bus')
        })

        watchEffect(async () => {
            if (props.showBtnMenuBottom) {
                await showBottomSheetBtn()
            } else {
                await hideBottomSheetBtn()
            }
        })

        return {
            resizeMapViewToFullScreen,
            closeMap,
            imageBase64,
            unSetMapImg,
            renderRouteLines,
            hideMapView,
            showMapView,
            setMapCamera,
            hideBottomSheetBtn,
            showBottomSheetBtn,
            updateMapViewWhenBoundingRectUpdated
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.google-map-native {
    width: 100%;
    height: 100%;
}
</style>
