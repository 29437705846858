<template>
    <ion-page id="live-tracking-page">
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="goBack" tabindex="0" aria-label="Go Back">
                        <ion-icon
                            aria-hidden="true"
                            slot="icon-only"
                            name="chevron-back-outline"
                            class="arrow-back-icon no-zoom-el"
                        ></ion-icon>
                    </ion-button>
                    <ion-button @click="openTipsModal" aria-label="Open instruction">
                        <ion-icon
                            slot="icon-only"
                            :icon="informationCircleOutline"
                            class="no-zoom-el search-icon"
                            aria-hidden="true"
                        ></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-capitalize no-zoom-el"
                    ><span ref="headerTitle" id="live-tracking-header-title" tabindex="-1" role="heading">Live Tracking</span></ion-title
                >
                <ion-buttons slot="end" class="search-btn" v-if="!isCardTrackingMode" id="btn-search-route">
                    <ion-button @click="onClickSearch" aria-label="Search Route">
                        <ion-icon
                            slot="icon-only"
                            :icon="search"
                            class="no-zoom-el search-icon"
                            aria-hidden="true"
                        ></ion-icon>
                    </ion-button>
                    <ion-button 
                        v-if="!isCardTrackingMode && computedTrackingRoutes.length > 0"
                        @click="showBottomMenuLayout" aria-label="Show Tracking Routes">
                        <ion-icon
                            slot="icon-only"
                            :icon="menuOutline"
                            class="no-zoom-el"
                            aria-hidden="true"
                        ></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <google-map-control
            v-if="isViewDidEnter"
            :stop-markers="computedStopMarkers"
            :bus-markers="computedBusMarkers"
            :route-paths="routePaths"
            :map-zoom="zoom"
            :on-click-stop-marker="onClickStopMarker"
            :on-click-bus-marker="onClickBusMarker"
            :my-marker-options="meMarkerOptions"
            :get-bottom-sheet-height="getBottomSheetHeight"
            :show-btn-menu-bottom="!isCardTrackingMode && computedTrackingRoutes.length > 0"
            :on-map-ready="onMapReady"
            :show-stop-times="computedShowStopTimes"
            ref="googleMapControl"
        ></google-map-control>
        <vue-bottom-sheet
            v-if="!isCardTrackingMode && computedTrackingRoutes.length > 0"
            class="bottom-sheet"
            isHiddenBar
            ref="swipeableBottomSheet"
            v-on:hide-bottom-sheet="hideBottomMenuLayout"
            v-on:bounding-rect-updated="onBottomSheetBoundingRectUpdated"
            :onClickSettingBtn="openSettingModal"
            :os-platform="platform"
        >
            <ion-list class="list-bottom-sheet">
                <div v-for="route in computedTrackingRoutes" v-bind:key="route.id">
                    <ion-item lines="none" class="route-track-item">
                        <ion-button
                            @click="moveToBusGps(route)"
                            aria-hidden="true"
                            class="container-btn bottom-bus-btn"
                            fill="clear"
                            :class="{ running: route.model.isRunning }"
                        >
                            <img
                                aria-hidden="true"
                                :src="route.markerDefinition.busMarkerImg"
                                slot="start"
                                class="bus-logo"
                                alt=""
                            />
                        </ion-button>
                        <ion-button
                            fill="clear"
                            @click="moveToBusGps(route)"
                            class="container-btn ion-text-wrap text-left-align label-btn"
                            :aria-label="'move map to route ' + route.routeName + ' of operator ' + route.operatorName"
                            v-bind:style="{
                                color: route.markerDefinition.color,
                                fontSize: 16 + 'px',
                                textTransform: 'capitalize',
                            }"
                            >{{ route.operatorName }} - {{ route.routeName }}</ion-button
                        >
                        <ion-buttons slot="end" size="large">
                            <ion-button class="btnLarge" @click="hideRoute(route)" fill="clear" size="large">
                                <ion-icon
                                    :icon="route.visible ? eyeOutline : eyeOffOutline"
                                    :aria-label="route.visible ? 'hide stop pins' : 'show stop pins'"
                                ></ion-icon>
                            </ion-button>
                            <ion-button
                                class="btnLarge"
                                @click="askToDelRouteTrack(route)"
                                fill="clear"
                                size="large"
                                aria-label="delete tracking route"
                            >
                                <ion-icon :icon="trashBinOutline"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <div
                        class="noti-container"
                        v-for="(stop, index) in route.trackingStops"
                        v-bind:key="`${stop.id}${index}`"
                    >
                        <ion-item lines="none">
                            <div class="stop-label-content">
                                <img
                                    aria-hidden="true"
                                    :src="route.markerDefinition.stopMarkerImg"
                                    slot="start"
                                    class="stop-logo"
                                    alt=""
                                />
                                <ion-label
                                    class="ion-text-wrap"
                                    v-bind:style="{
                                        color: route.markerDefinition.color,
                                        fontSize: '16px',
                                    }"
                                    >{{
                                        stop.display_name && stop.display_name.trim() ? stop.display_name : stop.name
                                    }}
                                    {{ stop.departure_time ? `(${stop.departure_time.slice(0, 5)})` : '' }}</ion-label
                                >
                            </div>
                            <ion-buttons slot="end" size="large">
                                <ion-button
                                    class="btnLarge"
                                    @click="askToDelStopTrack(route.operatorId, stop)"
                                    fill="clear"
                                    size="large"
                                    aria-label="delete tracking stop"
                                >
                                    <ion-icon :icon="trashBinOutline"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-item>
                    </div>
                </div>
            </ion-list>
        </vue-bottom-sheet>
        <ion-fab
            horizontal="end"
            @click="getApplyCurrentLocation"
            vertical="bottom"
            slot="fixed"
            class="fab-button"
            v-if="platform == 'web'"
        >
            <ion-fab-button size="small">
                <ion-icon :src="navigateCircleOutline"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </ion-page>
</template>

<script>
import {
    navigateCircleOutline,
    trashBinOutline,
    search,
    eyeOutline,
    eyeOffOutline,
    menuOutline,
    notificationsOutline,
    informationCircleOutline,
} from 'ionicons/icons'
import _ from 'lodash'
import moment from 'moment'
import routeApi from '../util/apis/route'
import RouteStopsModal from '../views/modal/RouteStopsModal'
import BusInforModal from '../views/modal/BusInfoModal'
import TipsModal from './modal/TipsModal.vue'
import SettingBottomSheetModal from './modal/SettingBottomSheetModal.vue'
import Favorite from '../components/favorite/Favorite.vue'
import { modalController } from '@ionic/vue'
import VueBottomSheet from '../components/bottom-sheet/VueBottomSheet'
import { Geolocation } from '@capacitor/geolocation'
import appService from '../util/services/appService'
import useNavigator from '@/composables/useNavigator'
import GoogleMapControl from './maps/GoogleMapControl.vue'
import { Capacitor } from '@capacitor/core'
import helperSrv from '../util/helper'

const MARKER_DEFINITION = [
    {
        name: 'bus-a',
        busMarkerImg: require('../assets/bus-a.png'),
        stopMarkerImg: require('../assets/stop-a.png'),
        color: '#007A78',
    },
    {
        name: 'bus-b',
        busMarkerImg: require('../assets/bus-b.png'),
        stopMarkerImg: require('../assets/stop-b.png'),
        color: '#d3273e',
    },
    {
        name: 'bus-c',
        busMarkerImg: require('../assets/bus-c.png'),
        stopMarkerImg: require('../assets/stop-c.png'),
        color: '#726012',
    },
    {
        name: 'bus-d',
        busMarkerImg: require('../assets/bus-d.png'),
        stopMarkerImg: require('../assets/stop-d.png'),
        color: '#7215d8',
    },
    {
        name: 'bus-e',
        busMarkerImg: require('../assets/bus-e.png'),
        stopMarkerImg: require('../assets/stop-e.png'),
        color: '#41b6e6',
    },
]

const CARD_TRACKING_MODE = 'SmartCardTracking'

export default {
    name: 'LiveTrackingJS',
    components: {
        GoogleMapControl,
        VueBottomSheet,
    },
    data: function () {
        return {
            loading: false,
            isMapReady: false,
            isViewDidEnter: false,
            trashBinOutline,
            notificationsOutline,
            eyeOutline,
            eyeOffOutline,
            navigateCircleOutline,
            search,
            menuOutline,
            notificationBell: require('../assets/stop-notification.svg'),
            currentLocation: require('../assets/user-location.png'),
            trackingRoutes: [],
            stopMarkers: [],
            busMarkers: [],
            routePaths: [],
            meMarkerOptions: {},
            busInterval: null,
            meLocationInterval: null,
            cacheCurrentLocation: null,
            zoom: 13,
            showRouteLines: {},
            hasGpsPermission: false,
            backEventHandler: null,
            platform: Capacitor.getPlatform(),
            showOnlyTrackingStops: false,
            showStopTimes: false,
            informationCircleOutline,
        }
    },
    computed: {
        isCardTrackingMode() {
            return this.$route.params.openMode == CARD_TRACKING_MODE
        },
        computedMyCoordinates() {
            return this.$store.getters.currentLocation
        },
        computedStopMarkers() {
            let result = []
            this.computedTrackingRoutes.forEach((route) => {
                if (!route.visible) {
                    return
                }
                const createStopMarker = (stop) => {
                    let stopMarkerOption = {
                        position: {
                            lat: parseFloat(stop.lat),
                            lng: parseFloat(stop.lng),
                        },
                        icon: {
                            url: route.markerDefinition.stopMarkerImg,
                            scaledSize: { width: 30, height: 40 },
                        },
                        stopModel: stop,
                        routeId: route.routeId,
                        routeViewModel: route,
                    }
                    if (this.showStopTimes) {
                        const departureTime = stop.departure_time
                            ? moment(stop.departure_time, 'HH:mm:ss').format('hh:mm')
                            : null
                        const labelText =
                            stop && departureTime ? `${stop.position} - ${departureTime}` : `${stop.position}`
                        stopMarkerOption.label = {
                            text: labelText,
                            color: '#3CAADE',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            className: 'label-marker-position',
                        }
                    }
                    return stopMarkerOption
                }
                if (this.showOnlyTrackingStops) {
                    result = result.concat(route.trackingStops.map((stop) => createStopMarker(stop)))
                } else {
                    result = result.concat(route.allStops.map((stop) => createStopMarker(stop)))
                }
            })

            return result
        },
        computedBusMarkers() {
            // Changed: always render bus markers, only hide stops
            return this.busMarkers.filter(() => true)
        },
        computedTrackingRoutes() {
            if (!this.trackingRoutes) {
                return []
            }
            let result = []
            this.trackingRoutes.forEach((company) => {
                company.trackable_routes.forEach((route) => {
                    const routeUID = company.id + '_' + route.id
                    result.push({
                        id: routeUID,
                        operatorName: company.name,
                        operatorId: company.id,
                        routeName: route.name,
                        routeId: route.id,
                        allStops: route.trackable_stops,
                        trackingStops: route.trackable_stops.filter((s) => s.tracked),
                        markerDefinition: this.getMarkerDef(routeUID),
                        visible: route.visible,
                        model: route,
                        remove: () => {
                            _.remove(company.trackable_routes, (routeModel) => {
                                return routeModel.id == route.id
                            })
                            this.removeLinkedMarkerDef(routeUID)
                            this.removeRouteLine(company.id, route.id)
                        },
                    })
                })
            })

            return result
        },
        computedShowStopTimes() {
            return this.showStopTimes
        },
    },
    ionViewDidEnter: function () {
        this.isViewDidEnter = true
        this.$refs.headerTitle.focus()
    },
    mounted: async function () {
        this.showOnlyTrackingStops = await this.getShowOnlyTrackingStopsFromLocal()
        this.showStopTimes = await this.getShowStopTimesFromLocal()
        this.addBackEventHandler()

        this.waitUntilMapReady(async () => {
            this.loadData()
            this.setIntervalBusMarker()
            if (this.platform == 'web') {
                const locationPermissions = await Geolocation.checkPermissions()
                this.hasGpsPermission = locationPermissions.location == 'granted'
                this.createMyMarker()
                this.setIntervalMeMarker()
            }
        })
    },

    async beforeUnmount() {
        clearInterval(this.busInterval)
        clearInterval(this.meLocationInterval)
        await this.backEventHandler.remove()
    },
    methods: {
        onMapReady() {
            this.isMapReady = true
        },
        waitUntilMapReady(executionFunc) {
            if (this.isMapReady) {
                return new Promise((resolve, reject) => {
                    const result = executionFunc()
                    if (result && result.then) {
                        result.then(resolve, reject)
                    } else {
                        resolve(result)
                    }
                })
            } else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.waitUntilMapReady(executionFunc).then(resolve, reject)
                    }, 200)
                })
            }
        },
        onBottomSheetBoundingRectUpdated() {
            this.$refs.googleMapControl.updateMapViewWhenBoundingRectUpdated()
        },
        isTrackingRoute(operatorRoutes, routeUID) {
            return operatorRoutes.find((company) => {
                return company.trackable_routes.find((route) => {
                    return company.id + '_' + route.id == routeUID
                })
            })
        },
        resetLinkedMarker(operatorRoutes) {
            MARKER_DEFINITION.forEach((def) => {
                if (def.linkedRouteId && !this.isTrackingRoute(operatorRoutes, def.linkedRouteId)) {
                    delete def.linkedRouteId
                }
            })
        },
        getMarkerDef(routeUID) {
            let linkedMarkerDef = MARKER_DEFINITION.find((markerDef) => markerDef.linkedRouteId == routeUID)
            if (linkedMarkerDef) {
                return linkedMarkerDef
            } else {
                linkedMarkerDef =
                    MARKER_DEFINITION.find((markerDef) => !markerDef.linkedRouteId) || MARKER_DEFINITION[0]
                linkedMarkerDef.linkedRouteId = routeUID
                return linkedMarkerDef
            }
        },
        removeLinkedMarkerDef(routeUID) {
            let linkedMarkerDef = MARKER_DEFINITION.find((markerDef) => markerDef.linkedRouteId == routeUID)
            if (linkedMarkerDef) {
                delete linkedMarkerDef.linkedRouteId
            }
        },
        removeRouteLine(companyId, routeId) {
            _.remove(this.routePaths, function (path) {
                return path.bus.route_id == routeId && path.bus.company_id == companyId
            })
            this.$refs.googleMapControl.renderRouteLines(this.routePaths)
        },
        async showBottomMenuLayout() {
            this.activeBottomMenuLayout()
            this.onBottomSheetBoundingRectUpdated()
        },
        async hideBottomMenuLayout() {
            if (this.$refs.googleMapControl) {
                await this.$refs.googleMapControl.resizeMapViewToFullScreen({
                    hideToggle: this.computedTrackingRoutes.length == 0,
                })
            }
        },
        async getApplyCurrentLocation() {
            if (this.hasGpsPermission) {
                if (this.$refs.googleMapControl.googleMapJs.map) {
                    this.$refs.googleMapControl.googleMapJs.map.panTo({
                        lat: this.cacheCurrentLocation.lat,
                        lng: this.cacheCurrentLocation.lng,
                    })
                }
            } else {
                await appService.presentAlert({
                    header: 'Location disabled',
                    message: 'Please enable location service from your phone Settings to use this feature.',
                    buttons: [
                        {
                            text: 'OK',
                            role: 'ok',
                        },
                    ],
                })
            }
        },
        moveToBusGps(route) {
            const runningBus = this.computedBusMarkers.find(
                (marker) => marker.routeId == route.routeId && marker.operatorId == route.operatorId
            )
            let location = {}
            if (runningBus && runningBus.position) {
                location = runningBus.position
            } else {
                const firstStop = route.allStops[0]
                if (firstStop) {
                    location = {
                        lat: parseFloat(firstStop.lat),
                        lng: parseFloat(firstStop.lng),
                    }
                }
            }
            this.$refs.googleMapControl.moveToLocation(location)
        },
        setIntervalBusMarker() {
            this.busInterval = setInterval(async () => {
                await this.createBusMarker()
            }, 10000)
        },
        setIntervalMeMarker() {
            this.meLocationInterval = setInterval(async () => {
                await this.createMyMarker()
            }, 10000)
        },
        async askToDelRouteTrack(route) {
            await this.$refs.googleMapControl.hideMapView()
            const _this = this
            appService.presentAlert({
                header: 'Route Tracking',
                message: 'Did you want to delete this tracking?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: async () => {
                            await _this.$refs.googleMapControl.showMapView()
                        },
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            await _this.delRouteTrack(route)
                        },
                    },
                ],
            })
        },
        changeMarkerStatus(marker, status) {
            marker.status = status
        },
        hideRoute(route) {
            route.model.visible = !route.model.visible
        },
        async delRouteTrack(route) {
            appService.presentLoading()
            try {
                await routeApi.delRouteTrack({
                    route_id: route.routeId,
                    operator_id: route.operatorId,
                })

                route.remove()
                this.eraseFavRoute({
                    route_id: route.routeId,
                    operator_id: route.operatorId,
                })
                this.eraseBusMarkers(route.operatorId, route.routeId)
            } finally {
                appService.dismissLoading()
                if (this.computedTrackingRoutes.length == 0) {
                    this.$refs.googleMapControl.resizeMapViewToFullScreen({ hideToggle: true })
                }
                this.$refs.googleMapControl.showMapView()
            }
        },
        eraseBusMarkers(operatorId, routeId) {
            _.remove(this.busMarkers, function (marker) {
                return marker.operatorId == operatorId && marker.routeId == routeId
            })
        },
        eraseFavRoute(params) {
            this.$store.dispatch('removeRouteTrack', params)
        },
        async openTipsModal() {
            this.$refs.googleMapControl.hideMapView()
            try {
                appService.saveTutorialStep('tips')
                let modal = await modalController.create({
                    component: TipsModal,
                    cssClass: 'tips-modal',
                })
                modal.present()

                await modal.onDidDismiss()
            } finally {
                this.$refs.googleMapControl.showMapView()
            }
        },
        async openMarkerModal(stopMarker) {
            try {
                let modal = await modalController.create({
                    component: RouteStopsModal,
                    cssClass: 'route-stop-modal',
                    componentProps: {
                        readOnly: this.isCardTrackingMode,
                        stopInfo: stopMarker,
                        trackingRouteViewModels: this.computedTrackingRoutes,
                        untrackStop: this.delStopReceiveNoti,
                    },
                })

                await modal.present()

                let modalResponse = await modal.onDidDismiss()

                modalResponse.data && this.modalCloseHandler({ ...modalResponse.data })
            } finally {
                this.$refs.googleMapControl.showMapView()
            }
        },
        async openBusMarkerModal(marker) {
            try {
                let modal = await modalController.create({
                    component: BusInforModal,
                    cssClass: 'bus-infor-modal',
                    componentProps: {
                        busInfo: marker,
                        showRouteLines: this.showRouteLines,
                    },
                })

                await modal.present()

                let modalResponse = await modal.onDidDismiss()
                modalResponse.data && this.busModalCloseHandler({ ...modalResponse.data })
            } finally {
                this.$refs.googleMapControl.showMapView()
            }
        },
        async openSettingModal() {
            this.$refs.googleMapControl.hideMapView()
            try {
                let modal = await modalController.create({
                    component: SettingBottomSheetModal,
                    cssClass: 'setting-bottom-sheet-modal',
                    componentProps: {
                        paramShowOnlyTrackingStops: await this.getShowOnlyTrackingStopsFromLocal(),
                        paramShowStopTimes: await this.getShowStopTimesFromLocal(),
                    },
                })

                await modal.present()

                let modalResponse = await modal.onDidDismiss()

                modalResponse.data && this.settingBottomSheetCloseHandler({ ...modalResponse.data })
            } finally {
                this.$refs.googleMapControl.showMapView()
            }
        },
        async getShowOnlyTrackingStopsFromLocal() {
            return JSON.parse(await localStorage.getItem('showOnlyTrackingStops'))
        },
        async getShowStopTimesFromLocal() {
            return JSON.parse(await localStorage.getItem('showStopTimes'))
        },
        async settingBottomSheetCloseHandler(res) {
            await localStorage.setItem('showOnlyTrackingStops', res.showOnlyTrackingStops)
            await localStorage.setItem('showStopTimes', res.showStopTimes)
            this.showOnlyTrackingStops = res.showOnlyTrackingStops
            this.showStopTimes = res.showStopTimes
        },
        async goBack() {
            // In case this screen opened from notification of bus arrived at stop then there is no card number
            console.log(this.$route.params.cardNumber)
            await this.$refs.googleMapControl.closeMap()
            if (this.isCardTrackingMode && this.$route.params.cardNumber) {
                useNavigator.goToSmartcard()
            } else {
                this.$router.replace('/main/home')
            }
        },
        busModalCloseHandler(response) {
            console.log('busModalCloseHandler', response)
            if (response) {
                this.createRouteLine(response)
            }
        },

        createRouteLine({ routeLines, bus, busColor, routeModel }) {
            if (routeLines.length > 0) {
                // cache routelines in model
                routeModel.routeLines = routeLines
                const locations = routeLines.map((r) => {
                    return {
                        lat: parseFloat(r.lat),
                        lng: parseFloat(r.lng),
                    }
                })
                const routePath = {
                    path: locations,
                    geodesic: true,
                    strokeColor: busColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    bus,
                }
                this.routePaths.push(routePath)
            } else {
                _.remove(this.routePaths, function (path) {
                    return path.bus.route_id == bus.route_id && path.bus.company_id == bus.company_id
                })
            }
            this.$refs.googleMapControl.renderRouteLines(this.routePaths)
        },
        async onClickStopMarker(marker) {
            await this.openMarkerModal(marker)
        },
        async onClickBusMarker(marker) {
            await this.openBusMarkerModal(marker)
        },
        async askToDelStopTrack(operatorId, stopModel) {
            await this.$refs.googleMapControl.hideMapView()
            const _this = this
            appService.presentAlert({
                header: 'Stop Tracking',
                message: 'Did you want to stop tracking this stop?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: async () => {
                            await _this.$refs.googleMapControl.showMapView()
                        },
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            await _this.delStopReceiveNoti(operatorId, stopModel, true)
                        },
                    },
                ],
            })
        },
        async delStopReceiveNoti(operatorId, stopModel, showMapView) {
            appService.presentLoading()
            try {
                const delParams = {
                    route_stops_id: stopModel.route_stop_id,
                    operator_id: operatorId,
                }
                console.log('deleting saved stop')
                const delRouteStop = await routeApi.delSavedStop(delParams)
                if (delRouteStop && delRouteStop.data && delRouteStop.data.delSavedStop.status === 'Success') {
                    stopModel.tracked = false
                    const showOnlyTrackingStops = await this.getShowOnlyTrackingStopsFromLocal()
                    if (showOnlyTrackingStops && stopModel.visible) {
                        stopModel.visible = false
                    }
                }
            } catch (err) {
                console.log('delStopReceiveNoti err', err.message)
                appService.dismissLoading()
                appService.showAlert(err.message, 'Live Tracking')
            } finally {
                console.log('delStopReceiveNoti-finally-dismissLoading ')
                appService.dismissLoading()
                if (showMapView) {
                    await this.$refs.googleMapControl.showMapView()
                }
            }
        },
        getBottomSheetHeight() {
            return this.$refs.swipeableBottomSheet.getHightBottomSheet()
        },
        activeBottomMenuLayout() {
            if (this.$refs && this.$refs.swipeableBottomSheet) {
                this.$refs.swipeableBottomSheet.activeBottomSheet(null, 'half')
                // get Y axis native maps
                return this.getBottomSheetHeight()
            }
        },

        async loadData() {
            let operatorRoutes = null
            if (this.isCardTrackingMode) {
                operatorRoutes = await routeApi.getCustomTrackingRoutes({
                    routes: [
                        {
                            operator_id: parseInt(this.$route.params.operatorId),
                            route_id: parseInt(this.$route.params.routeId),
                        },
                    ],
                })
            } else {
                operatorRoutes = await routeApi.getTrackingRoutes()
            }
            operatorRoutes.forEach((company) => {
                company.trackable_routes.forEach((route) => {
                    route.visible = true
                })
            })

            this.resetLinkedMarker(operatorRoutes)
            this.trackingRoutes = operatorRoutes

            await this.createBusMarker()

            if (this.computedTrackingRoutes.length) {
                this.moveToBusGps(this.computedTrackingRoutes[0])
            }

            if (this.computedTrackingRoutes.length == 0 || (await appService.getTutorialStep()) != 'tips') {
                await this.openTipsModal()
            }
        },

        createStopArgVuex(stop) {
            return {
                operator_id: stop.operator_id,
                stop_id: stop.stop_id,
                route_id: stop.route_id,
                stop_name: stop.stop_name,
                position: stop.position,
                cate_id: stop.cat_id,
                route_stops_id: stop.id,
            }
        },

        async createMyMarker() {
            const coordinates = await Geolocation.getCurrentPosition()
            if (coordinates && coordinates.coords) {
                this.meMarkerOptions = {
                    position: {
                        lat: parseFloat(coordinates.coords.latitude),
                        lng: parseFloat(coordinates.coords.longitude),
                    },
                    icon: {
                        url: this.currentLocation,
                        scaledSize: { width: 30, height: 40 },
                    },
                }
                this.cacheCurrentLocation = {
                    lat: parseFloat(coordinates.coords.latitude),
                    lng: parseFloat(coordinates.coords.longitude),
                }
            }
        },
        async createBusMarker() {
            const runningRoutes = await this.getRunningRoutes()

            if (this.isCardTrackingMode && !runningRoutes.length) {
                return
            }

            this.computedTrackingRoutes.forEach((trackingRoute) => {
                trackingRoute.model.isRunning = false
            })

            this.busMarkers = runningRoutes.map((route) => {
                const routeViewModel = this.computedTrackingRoutes.find((routeViewModel) => {
                    return routeViewModel.operatorId == route.operator_id && routeViewModel.routeId == route.route_id
                })

                routeViewModel.model.isRunning = true
                return {
                    zIndex: 100,
                    position: {
                        lat: route.lat,
                        lng: route.lng,
                    },
                    icon: {
                        url: routeViewModel.markerDefinition.busMarkerImg,
                        scaledSize: { width: 30, height: 36 },
                    },
                    busId: route.bus_id,
                    busNumber: route.bus_number,
                    routeId: route.route_id,
                    operatorId: route.operator_id,
                    dbrId: route.dbr_id,
                    paxAppShowPaxCount: route.pax_app_show_pax_count,
                    busColor: routeViewModel.markerDefinition.color,
                    routeViewModel: routeViewModel,
                }
            })
        },

        getTrackingOperatorRoutes() {
            let result = []
            this.trackingRoutes.forEach((company) => {
                result = result.concat(
                    company.trackable_routes.map((route) => ({
                        operator_id: company.id,
                        route_id: route.id,
                    }))
                )
            })
            return result
        },
        async hasTripEnded(currentTrips) {
            if (!currentTrips.length) {
                clearInterval(this.busInterval)
                clearInterval(this.meLocationInterval)
                await this.$refs.googleMapControl.hideMapView()
                const role = await appService.presentAlert({
                    header: 'Live Tracking',
                    message: 'The trip has ended',
                    buttons: [
                        {
                            text: 'OK',
                            role: 'ok',
                        },
                    ],
                })
                if (role === 'ok') {
                    this.goBack()
                }

                return true
            } else {
                return false
            }
        },
        async getRunningRoutes() {
            const routes = this.getTrackingOperatorRoutes()
            if (routes.length > 0) {
                const runningRoutes = await routeApi.getRunningRoutes({
                    routes: routes,
                })
                if (this.isCardTrackingMode) {
                    const currentTrips = runningRoutes.filter((route) => route.dbr_id == this.$route.params.dbrId)
                    if (await this.hasTripEnded(currentTrips)) {
                        return []
                    }
                    return currentTrips
                } else {
                    return runningRoutes
                }
            } else {
                return []
            }
        },
        async onClickSearch() {
            await this.$refs.googleMapControl.hideMapView()
            await this.removeBackEventHandler()
            await this.openSearchOperatorModal()
        },
        async addBackEventHandler() {
            this.backEventHandler = await appService.addListenerBackBtn(this.goBack)
        },
        async removeBackEventHandler() {
            if (this.backEventHandler) {
                await this.backEventHandler.remove()
            }
        },
        async openSearchOperatorModal() {
            try {
                let modal = await modalController.create({
                    component: Favorite,
                    cssClass: 'favorite-operator-modal',
                    componentProps: {
                        routeMode: 'trackingBus',
                    },
                })

                // Disable screen reader access previous page
                helperSrv.setElementAttribute('live-tracking-page', 'aria-hidden', true)
                //helperSrv.setElementAttribute("google-map-native", "aria-hidden", true);

                // show the modal
                await modal.present()

                // wait to see if i get a response
                await modal.onDidDismiss()

                // Enable screen reader again
                helperSrv.removeElementAttribute('live-tracking-page', 'aria-hidden')
                //helperSrv.removeElementAttribute("google-map-native", "aria-hidden");
            } finally {
                await this.$refs.googleMapControl.showMapView()
            }

            this.addBackEventHandler()

            await this.loadData()

            if (this.trackingRoutes && this.trackingRoutes.length) {
                this.showBottomMenuLayout()
            }
        },
    },
}
</script>

<style scoped>
#live-tracking-page {
    justify-content: start;
}
.bus-logo {
    width: 30px;
    height: 37px;
    -webkit-filter: invert(0%); /* Safari/Chrome */
    filter: invert(0%);
}
.stop-logo {
    width: 25px;
    height: 35px;
    -webkit-filter: invert(0%); /* Safari/Chrome */
    filter: invert(0%);
    margin-right: 12px;
}
.noti-container {
    margin-left: 55px;
    margin-bottom: 5px;
}
.noti-container .stop-label-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.bell-logo {
    font-size: 24px;
    min-width: 22px;
    color: var(--ion-color-secondary-shade);
    font-weight: bolder;
}
.bottom-sheet {
    z-index: 999;
}
.container {
    position: relative;
}
.text-left-align {
    text-align: left;
}
.google-map {
    width: 100%;
    height: 100%;
}
.google-map-cut {
    width: 100%;
    height: 73%;
    position: absolute;
}
.fab-button {
    right: 2px;
    bottom: 200px;
    z-index: 100;
}
.fab-menu-button {
    right: 2px;
    bottom: 32%;
}
.search-btn {
    margin-right: 12px;
}

.running {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    transform: scale(1);
    animation: animation-pulse 2.5s infinite;
}
.route-track-item {
    --padding-start: 5px;
    min-height: 70px;
}
.container-btn.bottom-bus-btn {
    min-width: 40px;
    min-height: 50px;
    margin-right: 15px;
    margin-left: 10px;
    padding: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
}
.container-btn.label-btn {
    height: 100%;
}
#live-tracking-header-title:focus {
    outline: none;
}
</style>
