<template>
    <ion-page>
        <ion-content class="content-list">
            <div class="header-container">
                <ion-label class="modal-title">Stop Information</ion-label>
            </div>

            <ion-list>
                <ion-item>
                    <ion-label class="ion-text-wrap">
                        Stop: {{ stopInfo.stopModel.display_name ? stopInfo.stopModel.display_name : stopInfo.stopModel.name }}
                    </ion-label>
                </ion-item>
                <ion-item
                    lines="none"
                    v-for="(routeStop, index) in routeStopsComputed"
                    v-bind:key="`${routeStop.routeStopId}`"
                >
                    <ion-label class="ion-text-wrap">
                        Route: {{ routeStop.routeName }}
                        <p v-if="routeStop.departureTime">
                            Departure time: {{ routeStop.departureTime || '' }}
                        </p>
                    </ion-label>
                    <ion-button
                        class="btnTrack"
                        v-if="!readOnly && !routeStop.tracked"
                        @click="onClickStop(routeStop)"
                        aria-label="Track this stop"
                        fill="clear"
                        size="large"
                        slot="end"
                    >
                        <ion-icon
                            class="bell-logo"
                            slot="icon-only"
                            :icon="notificationsOutline"
                            :id="index == 0 ? 'toggle-stop-tracking' : null"
                        ></ion-icon>
                    </ion-button>
                    <ion-button
                        class="btnTrack"
                        @click="onClickStop(routeStop)"
                        v-if="!readOnly && routeStop.tracked"
                        aria-label="Untrack this stop"
                        fill="clear"
                        size="large"
                        slot="end"
                    >
                        <ion-icon
                            class="bell-logo-change"
                            slot="icon-only"
                            :icon="notificationsOutline"
                            :id="index == 0 ? 'toggle-stop-tracking' : null"
                        ></ion-icon>
                    </ion-button>
                </ion-item>
            </ion-list>
            <div class="footer-container">
                <ion-button fill="clear" class="bottom-btn" @click="dismissModal()" color="primary" size="medium"
                    >Close</ion-button
                >
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import routeApi from '../../util/apis/route'
import { modalController } from '@ionic/vue'
import fcmService from '../../util/services/fcmService'
import useNavigator from '@/composables/useNavigator'
import { notificationsOutline } from 'ionicons/icons'

export default {
    name: 'RouteStopsModal',
    props: {
        readOnly: Boolean,
        trackingRouteViewModels: Array,
        stopInfo: Object,
        vueStore: Object,
        untrackStop: Function,
    },
    data: function() {
        return {
            notificationsOutline,
        }
    },
    computed: {
        routeStopsReceiveNoti() {
            return this.vueStore.getters.routeStopsReceiveNoti
        },
        routeStopsComputed() {
            const findStopsInRoute = (routeVM, stopInfo) => {
                return stopInfo.routeViewModel.operatorId == routeVM.operatorId
                    ? routeVM.allStops.filter(stop => stop.id == stopInfo.stopModel.id)
                    : null
            }

            const routeStops = []
            this.trackingRouteViewModels.forEach(routeVM => {
                const stopModels = findStopsInRoute(routeVM, this.stopInfo)
                if (stopModels && stopModels.length > 0) {
                    stopModels.forEach(stopModel => {
                        console.log(stopModel)
                        routeStops.push({
                            operatorId: routeVM.operatorId,
                            routeStopId: stopModel.route_stop_id,
                            departureTime: stopModel.departure_time ? stopModel.departure_time.slice(0, 5) : null,
                            routeName: routeVM.routeName,
                            tracked: stopModel.tracked,
                            stopModel: stopModel,
                        })
                    })
                }
            })
            console.log('routeStopsComputed', routeStops)
            return routeStops
        },
    },
    methods: {
        getBellImg(stop) {
            return stop.tracked ? this.notificationBell : this.notificationBellNone
        },
        dismissModal() {
            modalController.dismiss()
        },
        async onClickStop(stop) {
            if (stop.tracked) {
                await this.delStop(stop)
            } else {
                // check enable notifications
                if (await fcmService.registerPush(true)) {
                    await this.saveStops(stop)
                } else {
                    this.dismissModal()
                    useNavigator.goHome()
                }
            }
        },
        async delStop(stop) {
            this.untrackStop(stop.operatorId, stop.stopModel, false)
        },
        async saveStops(stop) {
            const apiParams = {
                route_stops_id: stop.routeStopId,
                operator_id: stop.operatorId,
            }
            const saveRouteStop = await routeApi.saveRouteStop(apiParams)
            if (saveRouteStop.data.saveRouteStops.status === 'Success') {
                stop.stopModel.tracked = true
            }
        },
    },
}
</script>
<style>
/* route stops modal style */
.route-stop-modal .modal-wrapper {
    width: 90vw;
    max-width: 400px;
    height: 50vh;
    max-width: 600px;
    border-radius: 6px;
}

.route-stop-modal .modal-wrapper .content-list {
    padding-bottom: 50px !important;
}
</style>
<style scoped>
.header-container {
    position: sticky;
    top: 0;
    z-index: 99;
}
.content-list {
    --padding-bottom: 50px !important;
}
.text-over {
    text-overflow: ellipsis;
    width: 250px;
    white-space: normal;
    margin-right: 5px;
    overflow: hidden;
}
.bell-logo {
    font-weight: bold;
    font-size: 30px;
    color: var(--ion-color-light-contrast);
}
.bell-logo-change {
    font-size: 30px;
    font-weight: bold;
    color: var(--ion-color-secondary-shade);
}
.modal-title {
    text-align: center;
    font-weight: bold;
    color: white;
}
ion-toolbar {
    --background: 'white';
}
.footer-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: right;
    background: white;
    z-index: 99;
}
.btnTrack::part(native) {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
}
</style>
