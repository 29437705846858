<template>
    <div class="wrapper" v-bind:class="{ 'in-active': state == 'close' }" :data-open="state === 'open' ? 1 : 0">
        <div class="bg" @click="() => setState('half')"></div>
        <div
            ref="card"
            class="card"
            :data-state="isMove ? 'move' : state"
            :style="{ top: `${isMove ? y : calcY()}px` }"
        >
            <div class="contents" v-show="state !== 'close'" :style="{ height: `calc(100vh - ${isMove ? y : calcY()}px)` }">
                <div class="pan-area" ref="pan">
                    <div class="place-holder"></div>
                    <ion-button ref="btn"
                        fill="clear"
                        class="container-btn"
                        @click="activeBottomSheet"
                        v-show="state !== 'close'"
                    >
                        <ion-icon
                            slot="icon-only"
                            class="open-btn"
                            ref="toggleBtn"
                            color="dark"
                            :aria-label="state === 'close'? 'Open tracking routes list': 'Close tracking routes list'"
                            :src="state === 'close' ? chevronUpOutline : chevronDownOutline"
                        ></ion-icon>
                    </ion-button>
                    <div class="setting-content" v-show="state !== 'close'" :aria-hidden="state === 'close'? true: null">
                        <ion-button class="container-btn" @click="onClickSettingBtn" aria-label="Live tracking settings" fill="clear">
                            <ion-icon slot="icon-only" class="setting-btn" :src="settingsOutline"></ion-icon>
                        </ion-button>
                    </div>
                </div>                
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { chevronUpOutline, chevronDownOutline, settingsOutline } from 'ionicons/icons'
import { createAnimation } from '@ionic/vue'

import { LiveTrackingBottomSheetInitHeight } from '../../util/constant'

export default {
    props: {
        openY: {
            type: Number,
            default: 0.1,
        },
        halfY: {
            type: Number,
            default: 0.6,
        },
        defaultState: {
            type: String,
            default: 'close',
        },
        barColor: {
            // Added a bar color props
            type: String,
            default: 'rgba(0, 0, 0, .3)',
        },
        isHiddenBar: {
            // Added a bar color props
            type: Boolean,
            default: false,
        },
        onClickSettingBtn: {
            type: Function,
        },
        osPlatform: {
            type: String,
            default: 'web',
        },
    },
    emits: ['hide-bottom-sheet', 'bounding-rect-updated'],
    data() {
        return {
            chevronUpOutline,
            chevronDownOutline,
            settingsOutline,
            y: 0,
            startY: 0,
            isMove: false,
            state: this.defaultState,
            rect: {},
        }
    },
    computed: {
        isWebPlatform() {
            return this.osPlatform == 'web'
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateBoundingRect)
        this.rect = this.$refs.card.getBoundingClientRect()
       
        this.toggleBtnAnimation = this.pulseButton()
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateBoundingRect)
    },
    methods: {
        updateBoundingRect() {
            console.log('updateBoundingRect')
            this.rect = this.$refs.card.getBoundingClientRect()
            setTimeout(() => {
                this.$emit('bounding-rect-updated')
            }, 100)
        },
        pulseButton() {
            const animation = createAnimation()
                .addElement(this.$refs.toggleBtn.$el)
                .duration(2000)
                .iterations(Infinity)
                .keyframes([
                    { offset: 0, boxShadow: '0 0 0 0 rgba(40, 77, 255, 0.4)' },
                    { offset: 0.7, boxShadow: '0 0 0 10px rgba(44, 103, 255, 0)' },
                    { offset: 1, boxShadow: '0 0 0 0 rgba(44, 103, 255, 0)' },
                ])

            animation.play()
            return animation
        },
        activeBottomSheet(event, forceState) {
            const show = () => {
                this.state = 'half'
                this.toggleBtnAnimation.stop()

                //Focus on toggle button
                setTimeout(() => {
                    const button = this.$refs.btn.$el.shadowRoot.querySelector('.button-native')
                    button && button.focus()
                }, 100)                
            }

            const hide = () => {
                this.state = 'close'
                this.toggleBtnAnimation.play()
                if (this.platform != 'web') {
                    this.$emit('hide-bottom-sheet')
                }
            }

            if (forceState) {
                forceState == 'close' ? hide() : show()
                return
            }

            switch (this.state) {
                case 'close':
                    show()
                    break
                default:
                    hide()
            }
        },
        calcY() {
            console.log(this.openY, this.halfY)
            switch (this.state) {
                case 'close':
                    return this.rect.height - LiveTrackingBottomSheetInitHeight
                case 'open':
                    return this.rect.height * this.openY
                case 'half':
                    return this.rect.height * this.halfY
                default:
                    return this.y
            }
        },
        getHightBottomSheet() {
            return this.rect.height - this.calcY()
        },
        setState(state) {
            this.state = state
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper[data-open='1'] {
    position: fixed;
    top: 0;
    left: 0;
}
.wrapper[data-open='1'] .bg {
    display: block;
    // transition: all 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
}
.card {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
    left: 0;
}
.card[data-state='half'],
.card[data-state='open'],
.card[data-state='close'] {
    // transition: top 0.3s ease-out;
}
.card[data-state='close'] {
    box-shadow: none;
}
.pan-area {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
}
.card[data-state='close'] {
    background-color: var(--ion-color-primary);
    .pan-area {
        top: 0px;
    }
}
.contents {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
    ion-list {
        padding-top: 0;
    }
}
.setting-btn {
    font-size: 25px;
    color: black;
    opacity: 0.6;
}
.place-holder {
    min-width: 50px;
}
.setting-content {
    margin-right: 20px;
    z-index: 1;
}
.open-btn {
    height: 35px;
    width: 35px;
    border-radius:50%;
}
.in-active .open-btn {
    bottom: 0;
    background-color: var(--ion-color-light);
}
</style>
