<template>
    <ion-content>
        <ion-grid class="content-grid">
            <ion-row class="header-row">
                <ion-col class="center-text">
                    <ion-text class="title-text ion-text-capitalize" role="heading">Tips for Map</ion-text>
                </ion-col>
                <ion-button @click="dismissModal()" class="close-btn" fill="clear" size="small" aria-label="close">
                    <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
                </ion-button>
            </ion-row>
            <ion-row>
                <ion-col class="center-text">
                    <ion-text class="desc-text">List of icons in this screen:</ion-text>
                </ion-col>
            </ion-row>
            <ion-row style="flex-grow: 1">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <div>
                                <div class="item-label">
                                    <img :src="busMarkerImg" class="bus-img" alt="" />
                                    <ion-text class="text-bold">
                                        <h6 class="no-margin">Bus icon</h6>
                                    </ion-text>
                                </div>

                                <ion-text class="text-body2-style">
                                    <ul class="note">
                                        <li>Only available when route is active and online</li>
                                        <li>Tap to view route detail</li>
                                    </ul>
                                </ion-text>
                                <div class="item-label">
                                    <img :src="stopMarkerImg" class="stop-img" alt="" />
                                    <ion-text>
                                        <h6 class="no-margin">Stop icon</h6>
                                    </ion-text>
                                </div>
                                <ion-text class="text-body2-style">
                                    <ul class="note">
                                        <li>Tap to view stop detail and configure notification</li>
                                    </ul>
                                </ion-text>
                                <div class="item-label">
                                    <ion-icon class="bell-logo" aria-hidden="true" :icon="notificationsOutline"></ion-icon>
                                    <ion-text><h6>Bell icon</h6></ion-text>
                                </div>
                                <ion-text class="text-body2-style">
                                    <ul class="note">
                                        <li>Tap on the bell to toggle bus arrival notification</li>
                                    </ul>
                                </ion-text>
                                <div class="item-label">
                                    <ion-icon class="bell-logo" aria-hidden="true" :icon="search"></ion-icon>
                                    <ion-text>
                                        <h6 class="no-margin">Search icon</h6>
                                    </ion-text>
                                </div>
                                <ion-text class="text-body2-style">
                                    <ul class="note">
                                        <li>Search and select operators/routes to track</li>
                                    </ul>
                                </ion-text>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>
            <ion-row style="margin-top:-25px;">
                <ion-col style="text-align: left;">
                    <ion-text class="desc-text">* Pinching with two fingers on the map to zoom in and out. Drag with one finger to move the map. {{isIOS && '(Note: Voice Over need to be switched off)'}}</ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
</template>

<script>
import { modalController } from '@ionic/vue'
import { notificationsOutline, search, closeOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'

export default {
    name: 'TipsModal',
    props: {},
    data: function() {
        return {
            busMarkerImg: require('../../assets/bus-b.png'),
            stopMarkerImg: require('../../assets/stop-b.png'),
            notificationsOutline,
            closeOutline,
            isIOS: Capacitor.getPlatform() == 'ios',
            search,
        }
    },

    methods: {
        dismissModal() {
            modalController.dismiss()
        },
    },
}
</script>
<style>
    /* tips modal */
    .tips-modal .modal-wrapper {
        width: 360px;
        height: 450px;
        max-height: 95%;
        border-radius: 10px;
    }
</style>
<style scoped>
.content {
    height: 100%;
}
.content-grid {
    padding: 0;
}

.header-row {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1;
    text-align: center;
    width: 100%;
    padding-top: 5px;
}
.center-text {
    text-align: center;
}

.close-btn {
    position: absolute;
    font-size: 15px;
    right: 0;
    top: 0;
}
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}
.item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title-text {
    font-style: normal;
    font-weight: bold;
    width: 100%;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.4px;
    max-width: 290px;
    color: black;
}
.no-scroll {
    --overflow: hidden;
}
.bus-img {
    width: 30px;
    height: 35px;
    margin-right: 10px;
}
.stop-img {
    width: 25px;
    height: 35px;
    margin-left: 2px;
    margin-right: 12px;
}
.desc-text {
    margin-top: 10px;
    margin-left: 10px;
    display: block;
    align-self: center;
}
.note {
    margin-top: 0px;
    margin-left: 20px;
}
.no-margin {
    margin-left: 0;
}

.bell-logo {
    font-size: 25px;
    margin-right: 15px;
    color: orange;
}
</style>
